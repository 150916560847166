<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :wholesale-representative="wholesaleRepresentative" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/wholesale-representatives/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/wholesale-representatives';

import { getCountryByKey } from '@/constants/enums/countries';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      wholesaleRepresentative: {
        id: '',
        name: '',
        surname: '',
        email: '',
        countries: [],
      },
    };
  },

  async mounted() {
    await this.fetchWholesaleRepresentative();
  },

  methods: {
    async fetchWholesaleRepresentative() {
      try {
        const { data } = await show(this.$route.params.id);

        const countries = data.data.countries.map((country) => getCountryByKey(country));

        this.wholesaleRepresentative = {
          ...data.data,
          countries,
        };
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Nie udało się pobrać danych przedstawiciela handlowego.',
        );
      }
    },
    async onSubmit(form) {
      try {
        await update(this.wholesaleRepresentative.id, form);

        this.showSuccessNotification('Dane zapisane', 'Przedstawiciel zagraniczny został zaktualizowany.');
        this.$router.push({ name: 'wholesale-representatives-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania przedstawiciela zagranicznego. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
